<template>
  <b-card
    class="card-transaction grafico-view"
    no-body
  >
    <b-card-header>
      <b-card-title>Caixa postal</b-card-title>
    </b-card-header>
    <b-card-body>
      <div
        v-for="(transaction, $index) in totais"
        :key="$index"
        class="transaction-item"
        style="margin-bottom: 2.6rem"
        @click.prevent.stop="$router.push({
          name: 'caixapostal-view',
          query: {
            filtroRapido: transaction.query ? transaction.query : '',
            dataInicio: transaction.dataInicio ? transaction.dataInicio : ''
          }
        })"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.titulo }}
            </h6>
            <small>{{ transaction.subTitulo }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="transaction.negativo ? 'text-danger':'text-success'"
        >
          {{ transaction.valor }}
        </div>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useCaixaPostal from './useCaixaPostal'

export default defineComponent({
  components: {
    VueApexCharts,
    FontAwesomeIcon,
  },
  setup() {
    const { totaisCaixaPostal } = useCaixaPostal()

    return {
      totais: totaisCaixaPostal,
    }
  },
})
</script>
